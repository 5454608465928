<template v-if="formActive">
    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist" v-show="!isFromOtherOffice">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="customer-tab" data-bs-toggle="tab" data-bs-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true" @click="setTab(1)">{{'1 - ' + $t('sales.carrier_validity')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="item-tab" data-bs-toggle="tab" data-bs-target="#items" type="button" role="tab" aria-controls="profile" @click="setTab(2)">{{'2 - ' + $tc('sales.tariff_items', 2)}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="terminal-tab" data-bs-toggle="tab" data-bs-target="#terminals" type="button" role="tab" aria-controls="terminals" @click="setTab(3)">{{'3 - ' + $tc('navigation.terminals', 2)}}</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent" :data-indirect="isFromOtherOffice">

    <div class="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">

        <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.carrier_validity')}}</h5>

        <FormItem id="voyage_status_id" :label="$t('overview.type')" v-model="tariff.region_type_id" type="buttongroup" sizeLabel="2" sizeItem="4" :options="regiontypes" />

        <FormItem id="carrier_id" :label="$tc('navigation.carriers', 2)" type="livesearch-list" v-model="tariff.carriers" :options="carriers" 
                  :content="tariff.carriers.name" sizeLabel="2" sizeItem="4" @add="addRates" @delete="deleteRates" />

        <div class="row mt-3">
            <label class="col-sm-2 col-form-label">{{$t('sales.valid_from_to')}}</label>
            <div class="col-sm-2 has-validation start-date">
                <FormItem id="startdate" type="datepicker" v-model="tariff.start_date" @onchange="validateDate" :extraOption="startDate" />
            </div>
            <div class="col-sm-2 has-validation end-date">
                <FormItem id="enddate" type="datepicker" v-model="tariff.end_date" @onchange="validateDate" :extraOption="endDate" />
                <div class="invalid-feedback"> <i class="fa fa-warning"></i>&nbsp;<span id="edatespan"> {{$t('errors.date_in_past')}} </span></div>
            </div>
        </div>
        <br />
        <FormItem id="available_to_other_offices" type="checkbox" v-model="tariff.available_to_other_offices" :label="$t('sales.available_to_other_offices')" />

    </div>

    <div class="tab-pane fade" id="items" role="tabpanel" aria-labelledby="item-tab">

        <h5 class="mt-4 mb-3 fw-bold">{{$tc('sales.tariff_items', 2)}}</h5>

        <div class="accordion" id="contract-accordion">
            <div v-for="(tariffitem,index) in tariff.tariff_items" :key="index" class="accordion-item">

                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button class="acc-header-item header-main accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+index" aria-expanded="true" :aria-controls="'collapse-'+index">
                        <div v-if="isOneOfEachFieldFilledIn(tariffitem)" class="d-flex w-100">
                            <div class="col-3">{{getLoadingPortsString(tariffitem, 'loading_port')}}</div>
                            <i class="col-1 fa fa-arrow-right mt-1"></i>
                            <div class="col-3">{{getLoadingPortsString(tariffitem, 'discharge_port')}}</div>
                            <div class="col-5">{{getCommodityNames(index)}}</div>
                        </div>
                        <span v-else>{{$t('sales.new_x', [$tc('sales.tariff_items', 1)])}}</span>
                    </button>
                    <template v-if="isFromOtherOffice">
                        <div class="acc-header-item header-remove-item"></div>
                        <div class="acc-header-item header-remove-item"></div>
                    </template>
                    <template v-else>
                        <button type="button" class="acc-header-item header-remove-item" @click="copyTariffItem(tariffitem, index)"><i class="fal fa-copy"></i></button>
                        <button type="button" class="acc-header-item header-remove-item" @click="deleteTariffItem(index)"><i class="fal fa-trash-alt"></i></button>
                    </template>
                    <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
                </div>

                <div class="accordion-collapse collapse" :id="'collapse-'+index" :aria-labelledby="'heading-'+index" data-bs-parent="contract-accordion">
                    <div class="accordion-body">
                        <div class="row mb-1">
                            <label class="col-6">{{$tc('sales.loading_ports', 2)}}</label>
                            <label class="col-6">{{$tc('sales.discharge_ports', 2)}}</label>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <FormItem :id="'loadingport-'+index" type="livesearch-list" v-model="tariffitem.loading_port" :options="ports" :content="tariffitem.loading_port.name" :disabled="isFromOtherOffice" />
                            </div>
                            <div class="col-6">
                                <FormItem :id="'dischargeport-'+index" type="livesearch-list" v-model="tariffitem.discharge_port" :options="ports" :content="tariffitem.discharge_port.name" :disabled="isFromOtherOffice" />
                            </div>
                        </div>
                        <TariffCommodityFormComponent :initialTariffcommodity="tariffitem.tariff_item_commodities" :masterRates="masterRatesList" :allCommodities="commodities" :office="tariff.office" :officeId="officeId"
                                                      :currencies="currencies" :calc_units="calc_units" :isFromOtherOffice="isFromOtherOffice" :creatorOfficeId="creatorOfficeId" >
                        </TariffCommodityFormComponent>

                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-center">
            <a class="btn btn-sm btn-primary w-25" title="Click to add item" v-show="!isFromOtherOffice" @click="addTariffItem()">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('sales.tariff_items', 1)])}}
            </a>
        </div>
        
    </div>
    <div class="tab-pane fade" id="terminals" role="tabpanel" aria-labelledby="terminal-tab">
        <h5 class="mt-4 mb-3 fw-bold">{{$tc('navigation.terminals', 2)}}</h5>
        <template v-for="port, portId in terminals" :key="port.portname">
            <label class="row" v-for="terminal in port.terminals" :key="terminal.id" :for="portId+'-'+terminal.id">
                <div class="col-auto">
                    <FormItem type="checkbox" v-model="terminal.selected" :id="portId+'-'+terminal.id" @onchange="(e) => setTerminals(e, portId, terminal.id)" />
                </div>
                <div class="col-2">{{port.portname}}</div>
                <div class="col-3">{{terminal.name}}</div>
            </label>
        </template>
    </div>
    <Files :files="tariff.file_info" @addFile="addFiles" parentItemName="files" />
    </div>

</template>

<script>
    import commodityService from "@/services/CommodityService"
    import relationService from "@/services/RelationService"
    import portService from '@/services/PortService'
    import currencyService from "@/services/CurrencyService";
    import calculationunitService from "@/services/CalculationunitService";
    import terminalService from"@/services/TerminalService";
    import TariffCommodityFormComponent from '../contractcommodity/TariffCommodityFormComponent.vue'
    import moment from 'moment'
    import store from '@/store/user'
    import dataStore from '@/store/data'
    import fileService from "@/services/FileService"
    import Files from '@/components/formpopup/Files.vue'

    export default {
        name: 'TariffForm',
        props: ['itemData'],
        components: {
            TariffCommodityFormComponent,
            Files
        },
        data() {
            return {
                region_type_id:'',
                itemId:'',
                isFromOtherOffice: false,
                tariff: { carriers: [], tariff_items: [] },
                startDate: '',
                endDate: '',
                customers: [],
                carriers: [],
                ports: [],
                regiontypes: [],
                commodities:[],
                currencies:[],
                calc_units:[],
                terminals: {},
                allTerminals: [],
                portIds: [],
                officeId: null,
                creatorOfficeId: null,
                submitted: false,
                loading: false,
                shouldAddMarketRates: false,
                hasGrimaldi: false,
                hasSallaum: false,
                invalid: {},
                contractType:  this.$route.name.toLowerCase().replace('s',''),
                selectedItemIndex: 0,
                currentTab: 1,
                masterRatesList: [],
                dropzoneTarget: null,
                dataItemsLoaded: 0,
                dataItemsToLoad: 7,
                isEditing: false,
                isCopying: false,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.tariff = val    
            }
            
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let message = "";
                let valid = true;
                let required = document.querySelectorAll('#formModal .dp__input_wrap input.is-invalid');

                //Check tab 1
                if(required.length > 0){
                    valid = false;
                    message = "You have filled in an invalid date";
                }
                if(this.tariff.region_type_id == null){
                    valid = false;
                    message = "You must decide between deep sea service or short sea service.";
                }
                if(!valid) {
                    alert(message);
                    this.setTab(1, true);
                    return valid;
                }

                //Check tab 2
                this.tariff.tariff_items.forEach(item => {
                    item.tariff_item_commodities.forEach(comm => {
                        comm.tariff_item_commodity_commodities.forEach(cc => {
                            if(!cc.has_measurement_conditionals){
                                cc.tariff_item_commodity_measurement_conditionals = [];
                            }
                        })
                    })
                });
                //Check ports
                required = document.querySelectorAll('#items .simple-typeahead+div');
                for(let column of required) {
                    if(column.childElementCount == 0){
                        valid = false;
                        message = "There are ports that are yet to be selected.";
                    }
                }
                //Check selects and number fields
                required = document.querySelectorAll('form input[type="number"][required], form select, form .input-group input:not(.measure-field), .measure-field[required]');
                for(let field of required) {
                    if(!field.value && !field.classList.contains('tarrif-input')){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                //Check if there is at least one item
                if(this.tariff.tariff_items.length == 0){
                    valid = false;
                    message = "There are no tariff items.";
                }

                if(!valid){
                    alert(message);
                    this.setTab(2, true);
                }
                else{
                    this.getTerminals();
                    for(let terminalId in this.tariff.tariff_terminals){
                        let terminal = this.tariff.tariff_terminals[terminalId];
                        if(!this.portIds.includes(Number(terminal.port_id))){
                            this.tariff.tariff_terminals.splice(terminalId, 1);
                        }
                        else{
                            delete terminal.id;
                            delete terminal.tariff_id;
                        }
                    }
                }
                return valid;
            },
            validateDate(){
                let sdate = new Date(this.tariff.start_date).getTime();
                let edate = new Date(this.tariff.end_date).getTime();
                let sdateSpan = document.getElementById("edatespan");
                let isValid = true;
                if(sdate > edate){
                    isValid = false;
                    sdateSpan.innerHTML = this.$t('errors.date_inverse');
                }
                if(isValid){
                    document.querySelector(".start-date .dp__input_wrap").classList.remove('is-invalid');
                    document.querySelector(".end-date .dp__input_wrap").classList.remove('is-invalid');
                    sdateSpan.parentElement.classList.remove('d-block');
                }
                else{
                    document.querySelector(".start-date .dp__input_wrap").classList.add('is-invalid');
                    document.querySelector(".end-date .dp__input_wrap").classList.add('is-invalid');
                    sdateSpan.parentElement.classList.add('d-block');
                }
            },
            getData() {
                return this.tariff;
            },
            setData(data) {
                this.isEditing = true;
                this.loading = true;
                if(data) { 
                    this.tariff = data;
                    this.isFromOtherOffice = data.office_creator_id != this.$parent.$parent.get('auth_office');
                    this.creatorOfficeId = data.office_creator_id;
                    this.shouldAddMarketRates = false;
                    if(this.isCopying) {
                        this.tariff.id = null;
                    }
                    this.tariff.tariff_items.forEach(item => {
                        if(this.isCopying) {
                            this.deleteIds(item);
                        }
                        item.tariff_item_commodities.forEach(comm => {
                            comm.tariff_item_commodity_commodities.forEach(cc => {
                                cc.has_measurement_conditionals = cc.tariff_item_commodity_measurement_conditionals.length > 0 ? 1 : 0;
                            })
                            if(!comm.tariff_item_commodity_market_levels.find(ml => ml.office_id == this.officeId) ){
                                this.shouldAddMarketRates = true;
                                const otherMarketLevel = comm.tariff_item_commodity_market_levels.find(ml => ml.office_id == this.creatorOfficeId);
                                comm.tariff_item_commodity_market_levels.push({
                                    tariff_item_commodity_id: comm.id,
                                    office_id: this.officeId,
                                    amount: this.isFromOtherOffice ? otherMarketLevel.amount : '',
                                    calculation_unit_id: this.isFromOtherOffice ? otherMarketLevel.calculation_unit_id : null,
                                    currency_id: this.isFromOtherOffice ? otherMarketLevel.currency_id : null
                                });
                            }
                            if(this.isFromOtherOffice || this.isCopying){
                                comm.tariff_item_commodity_carriers.forEach(carrier => {
                                    if(this.isFromOtherOffice && !carrier.buying_rate_calculation_unit_id)
                                        carrier.buying_rate_calculation_unit_id = comm.calculation_unit_id;
                                    if(this.isFromOtherOffice && !carrier.inter_company_rate_calculation_unit_id)
                                        carrier.inter_company_rate_calculation_unit_id = comm.calculation_unit_id;
                                });
                                comm.tariff_item_commodity_market_levels.forEach(level => {
                                    if(this.isFromOtherOffice && !level.calculation_unit_id)
                                        level.calculation_unit_id = comm.calculation_unit_id;
                                });
                            }
                            
                        });
                    });
                    this.currentTab = this.isFromOtherOffice ? 2 : 1;
                    this.setTab(this.currentTab, true);
                    this.loadData();
                    this.isCopying = false;
                    return;
                }
                this.setCustomerName(this.tariff.customer_id);
                if(this.tariff.fileInfo === undefined){
                    this.tariff.fileInfo = [];
                }
                this.$nextTick(() => {
                    //Add master rates
                    this.masterRatesList = [];
                    this.tariff.carriers.forEach(carr => {
                        carr.name = (carr.relation ? carr.relation.name : carr.name);
                        delete carr.relation;
                        this.masterRatesList.push(this.getNewRatesItem(carr.id, carr.name));
                        if(carr.id == 13) this.hasGrimaldi = true;
                        if(carr.id == 40) this.hasSallaum = true;
                    });
                    this.tariff.tariff_items.forEach(item => {
                        item.tariff_item_commodities.forEach(comm => {
                            comm.tariff_item_commodity_market_levels.forEach(ml => {
                                if(!ml.currency_id){
                                    ml.currency_id = window.defaultCurrency;
                                }
                                if(!ml.calculation_unit_id){
                                    ml.calculation_unit_id = null;
                                }
                            });
                        });
                    });
                    this.$nextTick(() => {
                        this.setTab(this.currentTab);
                        this.checkStepOne();
                        this.$forceUpdate();
                        if(this.tariff.tariff_items.length === 1){
                            window.setTimeout(() => document.querySelector('#heading-0 button').click(), 100);
                        }
                        this.loading = false;
                        this.dataItemsLoaded = 0;
                        console.log(this.tariff);
                    });
                });
            },
            reset(isEditing = false, isCopying = false) {
                this.isEditing = isEditing;
                this.shouldAddMarketRates = false;
                this.hasGrimaldi = false, this.hasSallaum = false;
                if(isEditing) return;
                this.isFromOtherOffice = false;
                this.creatorOfficeId = this.officeId;
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.contractType = this.$route.name.toLowerCase().replace('s','');
                    this.selectedItemIndex = 0;
                    this.isCopying = isCopying;
                    this.loadData();
                    return;
                }

                this.tariff = {
                    'start_date': moment().quarter(moment().quarter() + 1).startOf('quarter').format('YYYY-MM-DD'),
                    'end_date': moment().quarter(moment().quarter() + 1).endOf('quarter').format('YYYY-MM-DD'),
                    'carriers': [],
                    'region_type_id': null,
                    'file_info': [],
                    'office_creator_id': null,
                    'available_to_other_offices': false,
                    'tariff_items': [this.getNewTariffItem()],
                };

                let cName = document.getElementById("customer_name");
                if (cName != null) cName.value = "";
                this.masterRatesList = [];
                if(!this.isEditing){
                    this.$nextTick(() => {
                        const el = document.querySelector('button.accordion-button.collapsed');
                        if(el) el.click();
                    });
                }

                this.loading = false
                this.invalid = {}
                this.currentTab = 1;
                this.setTab(this.currentTab, true);
                this.checkStepOne();
            },
            getNewTariffItem(){
                return {
                    'number': null,
                    'loading_port': [],
                    'discharge_port': [],
                    'tariff_item_commodities': [{
                        'id': null,
                        'condition_id': null,
                        'sales_rate': '',
                        'commodity_id': null,
                        'calculation_unit_id': null,
                        'sales_rate_currency_id': window.defaultCurrency,
                        'sales_rate_unit_id': null,
                        'buying_rate': '',
                        'buying_rate_currency_id': window.defaultCurrency,
                        'buying_rate_calculation_unit_id': '',
                        'inter_company_rate_currency_id': window.defaultCurrency,
                        'inter_company_rate_calculation_unit_id': '',
                        'inter_company_rate': '',
                        'fac': 0,
                        'is_pro_rata': 0,
                        'tariff_item_commodity_carriers': this.hardCopy(this.masterRatesList),
                        'tariff_item_commodity_market_levels':[{
                            'tariff_item_commodity_id': null,
                            'office_id': this.officeId,
                            'amount': null,
                            'currency_id': window.defaultCurrency,
                            'calculation_unit_id': ''
                        }],
                        'tariff_item_commodity_commodities': [{'commodity_id': null, 'has_measurement_conditionals': 0, 'condition_id': null, 'tariff_item_commodity_measurement_conditionals': []}]
                    }]
                };
            },

            addFiles(fileInfo){
                if (this.tariff.id) {
                    fileService.upload({"file_info": [fileInfo],"id": this.tariff.id,"model": 'tariff'}).then(response => {
                        this.tariff.file_info.unshift(response.data); 
                    }).catch(error => console.log('error', error))
                } else {
                    this.tariff.file_info.unshift(fileInfo); 
                }
            },
            loadData() {
                relationService.index(null, null, 'customer').then(response => {
                    this.customers = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                relationService.index(null, null, 'carrier').then(response => {
                    this.carriers = response.data;
                    this.carriers.push({id: -1, name: 'Other'});
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                portService.index().then(response => {
                    this.ports = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                terminalService.index().then(response => {
                    this.allTerminals = response.data
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                commodityService.index().then(response => {
                    this.commodities = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                currencyService.index().then(response => {
                    this.currencies = response.data
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                calculationunitService.index().then(response => {
                    this.calc_units = response.data.filter(cu => cu.id < 6 || cu.id == 8);
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
            },
            addTariffItem() {
                //Close open accordion items
                let openAccordionItems = document.querySelectorAll(".accordion-button:not(.collapsed)");
                openAccordionItems.forEach(i => {i.click()});

                const newLength = this.tariff.tariff_items.push(this.getNewTariffItem());
                this.$nextTick(() => {
                    const newEl = document.querySelector('#heading-'+(newLength - 1)+' button.collapsed');
                    if(newEl) newEl.click();
                });
            },
            deleteTariffItem(index) {
                if(confirm("Are you sure you want to delete this tariff item?")) this.tariff.tariff_items.splice(index, 1);
            },
            copyTariffItem(item, index){
                if(confirm("Are you sure you want to copy this tariff item?")){
                    const newItem = this.hardCopy(item);
                    this.deleteIds(newItem);
                    this.tariff.tariff_items.splice(index + 1, 0, newItem);
                    const copyFrom = document.querySelector("#heading-" + index + " button");
                    if(!copyFrom.classList.contains("collapsed")) copyFrom.click();
                    this.$nextTick(() => {
                        document.querySelector("#heading-" + (index + 1) + " button").click();
                        this.$forceUpdate();
                    });
                }
            },
            deleteIds(newItem){
                delete newItem.id;
                delete newItem.tariff_id;
                newItem.tariff_item_commodities.forEach(comm => {
                    delete comm.id;
                    delete comm.tariff_item_id;
                    comm.tariff_item_commodity_carriers.forEach(c => {
                        delete c.id;
                        delete c.tariff_item_commodity_id;
                    });
                    comm.tariff_item_commodity_market_levels.forEach(c => {
                        delete c.id;
                        delete c.tariff_item_commodity_id;
                    });
                    comm.tariff_item_commodity_commodities.forEach(cc => {
                        delete cc.id;
                        delete cc.tariff_item_commodity_id;
                        cc.tariff_item_commodity_measurement_conditionals.forEach(mc => {
                            delete mc.id;
                            delete mc.tariff_item_commodity_commodity_id;
                        });
                    });
                });
            },

            setTab(tabId, shouldClickTab = false){
                this.currentTab = tabId;
                if(shouldClickTab){
                    document.querySelectorAll(".nav-link")[tabId - 1].click();
                }
                if(tabId == 3){
                    this.getTerminals();
                }
                else{
                    document.getElementById('nextStep').innerHTML = (tabId + 1);
                }
                document.getElementById('savebutton').style.display = tabId >= 2 ? 'block' : 'none';
                document.getElementById('nextbutton').style.display = tabId > 2 ? 'none' : 'block';

                if(this.tariff.region_type_id != null && this.tariff.carriers.length > 0){
                    let cType = this.tariff.region_type_id == "2" ? "Deep Sea" : "Short Sea";
                    let cNames = "";
                    let totalChosen = Math.min(3, this.tariff.carriers.length);
                    let index = 0;
                    this.tariff.carriers.slice(0, 3).forEach(carr => {
                        cNames += carr.name;
                        index++;
                        if(index == totalChosen) return;
                        cNames += " / ";
                    })
                    document.getElementById("detailsSpan").innerHTML = ": " + cType + ",&ensp;" + cNames + ",&ensp;" + this.formatDate(this.tariff.start_date, "shortDate") + " - " + this.formatDate(this.tariff.end_date, "shortDate");
                }
                else {
                    document.getElementById("detailsSpan").innerHTML = "";
                }
            },

            getTerminals(){
                let allPorts = [];
                this.portIds = [];
                this.tariff.tariff_items.forEach(item => {
                    item.loading_port.forEach(port => {
                        allPorts.push([port.id, port.name]);
                        this.portIds.push(port.id);
                    });
                });
                //Sort by name
                allPorts.sort((a, b) => {
                    return a[1] > b[1] ? 1 : -1;
                })
                this.terminals = {};
                allPorts.forEach(port => {
                    if(!this.terminals[port[0]]) this.terminals[port[0]] = {};
                    this.terminals[port[0]][['portname']] = port[1];
                })
                for(let port in this.terminals){
                    this.terminals[port]['terminals'] = this.allTerminals.filter(term => term.port_id == port);
                    this.terminals[port]['terminals'].forEach(terminal => {
                        if(this.tariff.tariff_terminals){
                            terminal.selected = this.tariff.tariff_terminals.find(tt => tt.port_id == port && tt.terminal_id == terminal.id) != null ? 1 : 0;
                        }
                    })
                }
            },
            setTerminals(e, portId, terminalId){
                if(!this.tariff.tariff_terminals){
                    this.tariff.tariff_terminals = [];
                }
                if(e.target.checked){
                    this.tariff.tariff_terminals.push({
                        terminal_id: terminalId,
                        port_id: portId
                    });
                }
                else{
                    const index = this.tariff.tariff_terminals.findIndex(tt => tt.port_id == portId && tt.terminal_id == terminalId);
                    if(index >= 0){
                        this.tariff.tariff_terminals.splice(index, 1);
                    }
                }
            },

            setCustomerName(id){
                this.customers.forEach(customer => {
                    if (id == customer.id){
                        document.getElementById("customer_name").value = customer.name;
                    }
                });
            },

            openAccordion(e){
                e.target.parentElement.children[0].click();
            },
            isOneOfEachFieldFilledIn(item) { 
                return item.loading_port.length > 0 && item.discharge_port.length > 0 && item.tariff_item_commodities.length > 0
            },
            getLoadingPortsString(item, portname){
                let pString = "";
                let pAmount = 0;
                let pExtra = 0;
                item[portname].forEach(port => {
                    if(pAmount == 0)
                        pString += port.name;
                    else if((pString.length + port.name.length) <= 20)
                        pString += (', ' + port.name);
                    else
                        pExtra += 1;
                    pAmount++;
                });
                if(pExtra > 0) pString += (" +" + pExtra);
                return pString;
            },
            getCommodityNames(index){
                let cString = "";
                let cAmount = 0;
                let cExtra = 0;
                let comms = document.querySelectorAll("#collapse-"+index+" .commodity-name");
                comms.forEach(el => {
                    let value = el.options[el.selectedIndex] ? el.options[el.selectedIndex].text : '';
                    if(value == this.$t('form.select'))
                        return;
                    if(cAmount == 0)
                        cString += value;
                    else if((cString.length + value.length) <= 30)
                        cString += (', ' + value);
                    else
                        cExtra += 1;
                    cAmount++;
                });
                if(cExtra > 0) cString += (" +" + cExtra);
                return cString;
            },
            addRates(cId = null)  {
                //Add rates to commodities
                let newIndex = this.tariff.carriers.findIndex( (el) => el.id == cId);
                var newArr = this.getNewRatesItem(cId, this.tariff.carriers[newIndex].name);
                this.masterRatesList.splice(newIndex, 0, this.hardCopy(newArr));
                if(cId == 13) this.hasGrimaldi = true;
                if(cId == 40) this.hasSallaum = true;
                this.tariff.tariff_items.forEach(item => {
                    item.tariff_item_commodities.forEach(comm => {
                        //Add rates to each commodity
                        comm.tariff_item_commodity_carriers.splice(newIndex, 0, this.hardCopy(newArr));
                    });
                });
                this.checkStepOne();
            },
            deleteRates(index) {
                if(this.masterRatesList[index].carrier_id == 13) this.hasGrimaldi = false;
                if(this.masterRatesList[index].carrier_id == 40) this.hasSallaum = false;
                this.masterRatesList.splice(index, 1);
                //Remove rates from all commodities too
                this.tariff.tariff_items.forEach(item => {
                    item.tariff_item_commodities.forEach(comm => {
                        //Remove rates from each commodity
                        comm.tariff_item_commodity_carriers.splice(index, 1);
                    });
                });
                this.checkStepOne();
            },
            getNewRatesItem(id, name){
                return {
                    'id': 0,
                    'carrier_id': id, 
                    'carrier_name': name,
                    'buying_rate_currency_id': window.defaultCurrency, 
                    'buying_rate': '', 
                    'buying_rate_calculation_unit_id': '',
                    'sales_rate_currency_id': window.defaultCurrency, 
                    'sales_rate_unit_id': null,
                    'sales_rate': '', 
                    'tariff_item_commodity_id': 0,
                    'inter_company_rate_currency_id': window.defaultCurrency,
                    'inter_company_rate_calculation_unit_id': '',
                    'inter_company_rate': '',
                    'fac': 0
                };
            },
            checkStepOne(){
                let canGoToStepTwo = this.tariff.region_type_id == null || this.tariff.carriers.length == 0;
                document.getElementById('nextbutton').disabled = canGoToStepTwo;
                document.getElementById('item-tab').disabled = canGoToStepTwo;
            },
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },
            checkData(){
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isEditing)
                        this.setData(false);
                    else
                        this.reset();
                }
            }
        },
        mounted() {
            this.officeId = store.getters.getCompanyId;
            this.regiontypes = dataStore.getters.getRegionTypes;
            this.startDate = moment().quarter(moment().quarter() + 1).startOf('quarter').format('YYYY-MM-DD'); 
            this.endDate = moment().quarter(moment().quarter() + 1).endOf('quarter').format('YYYY-MM-DD');
        }
    }
</script>

<style scoped>
    .carrier-column {margin-left: 14.5rem; width: 75%}
    .wrapper label { border-width: 1px; border-style: solid; position: relative; height: 150px; text-align: center; }
    .wrapper label span { padding-top: 45px; display: block; }
    .wrapper label div { background: no-repeat center; background-size: contain; width: 100%; height: 100%; }
    .wrapper input[type="radio"]:not(:checked) + label { border-color: #666 }
    .wrapper input[type="radio"]:checked + label, .wrapper input[type="radio"] + label:hover { border-color: #0d6efd; cursor: pointer }
    .wrapper input[type="radio"]:not(:checked) + label::before { content: '\25CB'; margin-right: 6px; color: #000; font-size: 24px; position: absolute; bottom: 0px; left: 50%; }
    .wrapper input[type="radio"]:checked + label::before { content: '\25CF'; margin-right: 6px; color: #0d6efd; font-size: 24px;  position: absolute; bottom: 0px; left: 50%;  }
    #extraButtonStep2 {position: absolute; left: 1rem; bottom: 1rem}
    .accordion-item {
        margin-bottom: 1rem;
        border: 2px solid #0d6efd;
        border-radius: 6px
    }
    .acc-header-item { border: none }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        width: calc(100% - 100px);
        box-shadow: none !important;
        border-radius: 0px !important;
        transition: background-color .15s
    }
    .acc-header-item.header-remove-item, .acc-header-item.header-arrow {
        width: 50px;
        transition: background-color .15s
    }
    .accordion-button:not(.collapsed), .accordion-button:not(.collapsed) + *, .accordion-button:not(.collapsed) + * + *, .accordion-button:not(.collapsed) + * + * + .header-arrow {
        background-color: #0d6efd;
        color: white
    }
    .accordion-button.collapsed, .accordion-button.collapsed + *, .accordion-button.collapsed + * + * , .acc-header-item.collapsed + * + * + .header-arrow {
        background-color: white;
        color: #0d6efd;
        font-weight: bold;
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .accordion-button:not(.collapsed) + * + * + .acc-header-item.header-arrow::after {
        rotate: 90deg
    }

    /* Search list */
    .search-list {
        position: fixed;
        background-color: white;
        z-index: 1000;
        border: 1px solid black;
        border-radius: 4px;
        padding: 2px;
        max-height: 300px;
        overflow-y: scroll
    }
    .search-list > div:hover {
        background-color: #adf;
        cursor: pointer;
    }
    .search-list > .hover {
        background-color: #cef
    }

    #myTabContent[data-indirect="true"] #customer{
        display: none !important
    }

    #myTabContent[data-indirect="true"] #items{
        display: block !important;
        opacity: 1 !important
    }
</style>