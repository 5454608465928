<template>
<div class="mt-1" v-for="(contractcommodity,index) in thiscontractcommodity" :key="index">
    <hr>
    <div class="row">
        <div class="col-sm-4">
            <div>{{$tc('navigation.commodities', 2)}}</div>
        </div>
        <div class="col-sm-2 ps-1">
            <div>{{$t('form.condition')}}</div>
        </div>
        <div class="col-sm-5 ps-0">
            <div>{{$t('masterdata.m_conditionials')}} </div>
        </div>
        <div class="col-sm-1" >
            <a class="btn btn-sm text-danger float-right" title="Click to delete commodity" v-show="!isFromOtherOffice" @click="deleteTariffCommodity(index, true)"><i class="far fa-trash-alt"></i></a>
        </div>
    </div>
    <div class="row">
        <div v-for="(item, commIndex) in contractcommodity.tariff_item_commodity_commodities" :key="commIndex" class="row">
            <div class="col-sm-4 commodity-column mb-2">
                <select v-model="item.commodity_id" class="form-select form-select-sm commodity-name w-85 d-inline" :id="'select_'+index+'_'+commIndex" :disabled="isFromOtherOffice" :required="true">
                  <option :value="null">{{$t('form.select')}}</option>
                  <option v-for="option in allCommodities" :key="option.id" :value="option.id" :disabled="checkIfAlreadyChosen(id+commIndex, option.id, option)"> {{option.name}} </option>
                </select>
                <button type="button" class="btn text-danger p-0 ms-3" @click="deleteListItem(commIndex, contractcommodity.tariff_item_commodity_commodities)" v-show="!isFromOtherOffice">
                    <i class="far fa-close"></i>
                </button>
            </div>
            <div class="col-2">
                <FormItem :id="'cond_'+index+'_'+commIndex" v-model="item.condition_id" :options="conditions" type="select" :disabled="isFromOtherOffice" :placeholder="$t('form.all')" />
            </div>
            <div class="col-auto" style="width: 3%">
                <FormItem :id="'per_'+index+'_'+commIndex" v-model="item.has_measurement_conditionals" type="checkbox" :disabled="isFromOtherOffice" @click="setFirstCondition(item)" />
            </div>
            <div class="col-4">
                <div v-for="(condition, condIndex) in item.tariff_item_commodity_measurement_conditionals" :key="condIndex" class="row mb-1" v-show="item.has_measurement_conditionals">
                    <div class="col-3">
                        <FormItem :id="'per2_'+index+'_'+commIndex" v-model="condition.operator" :options="conditionDirections" type="select" :required="item.has_measurement_conditionals" :disabled="isFromOtherOffice" />
                    </div>
                    <div class="col-3">
                        <FormItem :id="'per3_'+index+'_'+commIndex" v-model="condition.measurement_conditional_id" :options="measurementConditionals" type="select" :required="item.has_measurement_conditionals" :disabled="isFromOtherOffice" />
                    </div>
                    <div class="col-5">
                        <FormItem :id="'per4_'+index+'_'+commIndex" type="number-with-tags" v-model="condition.value" :required="item.has_measurement_conditionals" :disabled="isFromOtherOffice"
                                  :endTag="condition.measurement_conditional_id ? measurementConditionalTags[condition.measurement_conditional_id - 1] : ''" />
                    </div>
                    <button type="button" class="col-auto btn text-danger p-0" @click="deleteCondition(item, condIndex)" v-show="!isFromOtherOffice">
                        <i class="far fa-close"></i>
                    </button>
                </div>
            </div>
            <div class="col-auto" style="width: 5%" v-show="item.has_measurement_conditionals">
                <a class="btn btn-sm btn-primary px-2" title="Click to add" @click="addCondition(item)" v-show="!isFromOtherOffice">
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </div>
    </div>
    <a class="btn btn-sm btn-primary add-comm-button" title="Click to add item" v-show="contractcommodity.tariff_item_commodity_commodities && contractcommodity.tariff_item_commodity_commodities.length < allCommodities.length && !isFromOtherOffice" 
      @click="addCommodity(contractcommodity)"> <span class="fa fa-plus"></span> </a>
    <div v-if="contractcommodity.tariff_item_commodity_commodities && contractcommodity.tariff_item_commodity_commodities.length == 0" class="no-items"></div>

    <div class="row mt-5" v-if="contractcommodity.tariff_item_commodity_carriers && contractcommodity.tariff_item_commodity_carriers.length > 0">
        <div class="col-sm-4" v-if="!isFromOtherOffice">
            <div class="mb-2">{{$tc('sales.buying_rates', 2)}}</div>
            <div class="row mb-2" v-for="(rate, index) in contractcommodity.tariff_item_commodity_carriers" :key="index">
                <div class="col-sm-5">
                    <div class="mt-1">{{masterRates[index] ? masterRates[index].carrier_name : ''}}</div>
                </div>
                <div class="col-sm-7">
                    <FormItem :id="'buying_'+index+'_'+contractcommodity.id" type="currency-and-unit" v-model="contractcommodity.tariff_item_commodity_carriers[index]" 
                        :options="{ 'currencies': currencies, 'calculation_units': calc_units}" :required="true" inputGroupType="buying_rate" sizeItem="12" @onchange="validate($event)" :calcUnitFields="calcUnitFields" />
                    <FormItem :id="'fac_'+index+'_'+contractcommodity.id" type="number-with-tags" v-model="rate.fac" label="FAC%" sizeLabel="3" sizeItem="9" endTag="%" />
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="mb-2" v-if="!isFromOtherOffice">{{$tc('sales.buying_rates_companies', 2)}}</div>
            <div class="mb-2" v-else> {{$tc('sales.buying_rates', 2) + ' ' + ('(NMT' + office.relation.country.iso + ')')}}</div>
            <div class="row mb-2" v-for="(rate, index) in contractcommodity.tariff_item_commodity_carriers" :key="index">
                <div class="col-sm-5">
                    <div class="mt-1">{{masterRates[index] ? masterRates[index].carrier_name : ''}}</div>
                </div>
                <div class="col-sm-7">
                    <FormItem :id="'office_'+index+'_'+contractcommodity.id" type="currency-and-unit" v-model="contractcommodity.tariff_item_commodity_carriers[index]" :disabled="isFromOtherOffice"
                        :options="{ 'currencies': currencies, 'calculation_units': calc_units}" :required="true" inputGroupType="inter_company_rate" sizeItem="12" @onchange="validate($event)" :calcUnitFields="calcUnitFields" />
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <template v-for="(rate, rindex) in contractcommodity.tariff_item_commodity_market_levels" :key="rindex">
            <template v-if="rate.office_id == this.creatorOfficeId && !isFromOtherOffice">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <div>{{$tc('sales.sales_rates', 2)}} <span v-if="isFromOtherOffice">{{('(NMT' + office.relation.country.iso + ')')}}</span></div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-5">
                        <div>{{$t('form.market')}}</div>
                    </div>
                    <div class="col-sm-7">
                        <!--<FormItem :id="'salerate_'+index" v-model="rate.amount" type="number" :disabled="isFromOtherOffice" />-->
                        <FormItem :id="'office_'+rindex+'_'+contractcommodity.id" type="currency-and-unit" v-model="contractcommodity.tariff_item_commodity_market_levels[rindex]" :disabled="isFromOtherOffice"
                        :options="{ 'currencies': currencies, 'calculation_units': calc_units}" :inputGroupType="false" sizeItem="12" @onchange="validate($event)" :calcUnitFields="calcUnitFields" />
                    </div>
                </div>
            </template>
            </template>
        </div>
        <div class="col-sm-4" v-if="isFromOtherOffice">
            <template v-for="(rate, rindex) in contractcommodity.tariff_item_commodity_market_levels" :key="rindex">
            <template v-if="rate.office_id == this.officeId">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <div>{{$tc('sales.sales_rates', 2)}}</div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-5">
                        <div>{{$t('form.market')}}</div>
                    </div>
                    <div class="col-sm-7">
                        <!--<FormItem :id="'salerate_'+index" v-model="rate.amount" type="number" :placeholder="rate.placeholder" />-->
                        <FormItem :id="'office_'+rindex+'_'+contractcommodity.id" type="currency-and-unit" v-model="contractcommodity.tariff_item_commodity_market_levels[rindex]"
                        :options="{ 'currencies': currencies, 'calculation_units': calc_units}" :inputGroupType="false" sizeItem="12" @onchange="validate($event)" :calcUnitFields="calcUnitFields" />
                    </div>
                </div>
            </template>
            </template>
        </div>
    </div>
    <div v-else class="mt-4">
        {{$t('form.no_selected', [$tc('navigation.carriers', 1)])}}
    </div>
    <div class="col-auto" style="width: 40%" v-if="$parent.hasGrimaldi || $parent.hasSallaum" >
        <FormItem type="slider" v-model="contractcommodity.is_pro_rata" :options="[1,0]" 
                 :label="($parent.hasGrimaldi ? ($parent.hasSallaum ? 'Grimaldi/Sallaum' : 'Grimaldi') : 'Sallaum') + ' Pro-Rata rate'" />
    </div>
</div>

<div class="row mt-4 justify-content-center">
    <a class="btn btn-sm btn-primary w-25 mb-4" title="Click to add commodity" @click="addTariffCommodity()" v-show="!isFromOtherOffice">
        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('navigation.commodities', 1)])}}
    </a>
</div>
                        
</template>

<script>
    import dataStore from '@/store/data'
    export default {
        props: ['initialTariffcommodity','masterRates','allCommodities','currencies','calc_units', 'isFromOtherOffice', 'office', 'officeId', 'creatorOfficeId'],
        name: 'TariffcommodityListComponent',
        computed: {
            thiscontractcommodity: {
                get() {
                    return this.initialTariffcommodity
                }
            }
        },
        watch: {
            initialTariffcommodity: {
                deep: true,
                handler(initialTariffcommodity) {
                    this.$emit('contractcommoditychange', initialTariffcommodity)
                }
            }
        },
        data() {
            return {
                tariff_item_commodity_carriers: this.masterRates,
                submitted: false,
                loading: false,
                invalid: {},
                defaultCurrency: null,
                measurementConditionalTags: ['cm', 'cm', 'cm', 'kg', 'm²', 'm³', 'w/m'],
                conditionDirections: [{id: 1, name: '>'}, {id: 2, name: '<='}],
                conditions: [],
                measurementConditionals: [],
                calcUnitFields: ['currency_id', 'calculation_unit_id']
            }
        },
        methods: {

            validate(e){
                let el = e.target;
                if(el.value) {
                    if(el.nodeName != "SELECT")
                        el.classList.add('is-valid');
                    el.classList.remove('is-invalid');
                }
                else el.classList.remove('is-valid');
            },
            
            addTariffCommodity(isSoft = false) {
                this.thiscontractcommodity.push({
                    condition_id: null,
                    commodity_id: null,
                    calculation_unit_id: null,
                    currency_id : window.defaultCurrency,
                    sales_rate: '',
                    sales_rate_currency_id: window.defaultCurrency,
                    sales_rate_unit_id: null,
                    buying_rate: '',
                    is_pro_rata: 0,
                    buying_rate_currency_id: window.defaultCurrency,
                    buying_rate_calculation_unit_id: '',
                    tariff_item_commodity_carriers: isSoft ? [] : this.hardCopy(this.masterRates),
                    tariff_item_commodity_market_levels: [{
                        tariff_item_commodity_id: null,
                        office_id: this.officeId,
                        amount: null,
                        currency_id: window.defaultCurrency,
                        calculation_unit_id: null
                    }],
                    tariff_item_commodity_commodities: [{'commodity_id': null, 'has_measurement_conditionals': 0, 'condition_id': null, 'tariff_item_commodity_measurement_conditionals': []}]
                });
            },
            deleteTariffCommodity(index, askForConfirm = false) {
                if(askForConfirm && confirm("Are you sure you want to delete this commodity?")) this.thiscontractcommodity.splice(index, 1);
                if (!askForConfirm) this.thiscontractcommodity.splice(index, 1);
            },
            tempSalesRatesofficesAlso(item, field, e){
                //Temporary - also fill in the sales rates for each commodity carrier
                //Make sure quotations and contracts still work without sales rates per community carrier before removing this!
                item.tariff_item_commodity_carriers.forEach(carrier => {
                    carrier[field] = e.target.value;
                })
            },
            setCalculationUnits(item){
                item.tariff_item_commodity_carriers.forEach(carrier => {
                    if(!carrier.buying_rate_calculation_unit_id)
                        carrier.buying_rate_calculation_unit_id = item.calculation_unit_id;
                    if(!carrier.inter_company_rate_calculation_unit_id)
                        carrier.inter_company_rate_calculation_unit_id = item.calculation_unit_id;
                });
                item.tariff_item_commodity_market_levels.forEach(level => {
                    if(!level.calculation_unit_id)
                        level.calculation_unit_id = item.calculation_unit_id;
                });
            },
            addCommodity(st){
                st.tariff_item_commodity_commodities.push({'commodity_id': null, 'condition_id': null, 'has_measurement_conditionals': 0, 'tariff_item_commodity_measurement_conditionals': []});
            },
            setFirstCondition(st){
                if(!st.tariff_item_commodity_measurement_conditionals || st.tariff_item_commodity_measurement_conditionals.length == 0){
                    st.tariff_item_commodity_measurement_conditionals = [];
                    st.tariff_item_commodity_measurement_conditionals.push({
                        operator: null,
                        value: null,
                        measurement_conditional_id: null
                    });
                }
            },
            addCondition(st){
                st.tariff_item_commodity_measurement_conditionals.push({
                    operator: null,
                    value: null,
                    measurement_conditional_id: null
                });
            },
            deleteCommodity(st, index){
                st.tariff_item_commodity_commodities.splice(index, 1);
            },
            deleteCondition(st, index){
                st.tariff_item_commodity_measurement_conditionals.splice(index, 1);
            },
            
            checkIfAlreadyChosen(elId, optionValue, commodity){
                let comms = [];
                const select = document.getElementById(elId);
                if(select != null && select.value === optionValue) return true;
                if(this.extraOption){
                    this.extraOption.forEach(tscc => {
                        comms.push(tscc.id);
                    });
                    return comms.includes(commodity.id);
                }
                else{
                    this.thiscontractcommodity.forEach(tsc => {
                        tsc.tariff_item_commodity_commodities.forEach(tscc => {
                            if((!tscc.tariff_item_commodity_measurement_conditionals || tscc.tariff_item_commodity_measurement_conditionals.length == 0) && tscc.condition_id === null){
                                comms.push(tscc.commodity_id);
                            }
                        })
                    })
                }
                return comms.includes(commodity.id);
            },
            deleteListItem(itemIndex, list){
                list.splice(itemIndex, 1);
            },

            forceUpdate(e){
                this.validate(e);
                this.addTariffCommodity(true);
                this.deleteTariffCommodity(this.thiscontractcommodity.length - 1);
            },
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            }
            
        },
        mounted() {
            this.measurementConditionals = dataStore.getters.getMeasurementConditionals;
            this.conditions = dataStore.getters.getConditions;
        }
    }
</script>
<style>
#items option[disabled] { display: unset !important }
.commodity-column > div > div > div:first-child > select{margin-top: 0 !important}
/* Multiple select fields list*/
option[disabled]{
  display: none
}
.w-85 {
  width: 85% !important
}
.add-comm-button {
  position: absolute;
  margin-top: 0.25rem
}
select.narrow{
  padding-right: 1.25rem;
  background-position-x: right 3px
}
.col-sm-7 .mb-2{
    margin: 4px 0px 4px 0px !important
}
.col-sm-7 .col-sm-9{
    padding-right: 0 !important
}
</style>